import { useState, useEffect, FC, ReactNode } from 'react';
import { isServer, isHydrating } from '../utils/detect';


export type DelayedProps = {
  children: ReactNode;
  waitBeforeShow?: number;
  isVisible?: boolean;
};


const Delayed: FC<DelayedProps> = ({ children, waitBeforeShow=500, isVisible }) => {
  if(isVisible === undefined) isVisible = true;

  const [isShown, setIsShown] = useState(isServer || isHydrating());

  // hide after isVisible is set to false
  useEffect(() => {
    // rerender when already hidden or isVisible is true, no need to hide again
    if(!isShown || isVisible) return;

    const timer = setTimeout(() => {
      setIsShown(false);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [isVisible, waitBeforeShow, isShown]);

  // show after mounted or isVisible is set to true
  useEffect(() => {
    // rerender when already visible or isVisible is false, no need to show again
    if(isShown || !isVisible) return;

    const timer = setTimeout(() => {
      setIsShown(!!isVisible);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow, isShown, isVisible]);

  return isShown ? children : null;
};

export default Delayed;
